import { JCRichText } from "@/components";
import { isFilled } from "@prismicio/helpers";
import classNames from "classnames";
const textAlign = {
    center: "text-center",
    left: "text-left",
    right: "text-right",
};
export const TextHeadline = ({ slice: { primary, items }, className }) => (<div className={classNames(className, "py-5 px-5 text-gray-base", textAlign[primary.text_align] || "text-center")}>
        {items
        .filter((i) => isFilled.richText(i.rich_text_line))
        .map((item, index) => (<div key={index}>
                    <JCRichText field={item.rich_text_line}/>
                </div>))}
    </div>);
