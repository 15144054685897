import { asLink, isFilled } from "@prismicio/helpers";
import React, { useContext } from "react";
export const FeaturedProductsContext = React.createContext({
    products: [],
});
export const useFeaturedProducts = () => useContext(FeaturedProductsContext);
export const toFeaturedValidProduct = (item, product) => {
    var _a, _b;
    return {
        cover: item.cover,
        url: asLink(item.product_link) || "",
        target: isFilled.link(item.product_link) && item.product_link.link_type === "Web" ? item.product_link.target : "",
        price: product.price.centAmount / Math.pow(10, product.price.fractionDigits),
        firstPrice: product.firstPrice
            ? product.firstPrice.centAmount / Math.pow(10, product.firstPrice.fractionDigits)
            : 0,
        name: product.name,
        path: product.path,
        discountedPrice: product.discountedPrice
            ? product.discountedPrice.centAmount / Math.pow(10, product.discountedPrice.fractionDigits)
            : 0,
        promotionalBanner: ((_b = (_a = product.promotionalBanner) === null || _a === void 0 ? void 0 : _a.name) === null || _b === void 0 ? void 0 : _b[process.env.NEXT_PUBLIC_LOCALE]) || "",
        id: product.id,
        attributes: product.attributes,
    };
};
