import { CTA, JCRichText } from "@/components";
import { Link, NextImage } from "@jog/react-components";
import { replacePlaceholderInUrl } from "@jog/shared";
import { asImageSrc, asLink, asText, isFilled } from "@prismicio/helpers";
import classNames from "classnames";
import { useRouter } from "next/router";
export const SingleBanner = ({ slice: { primary, items }, className }) => {
    var _a, _b, _c, _d;
    const showButtons = (!!primary.buttons_top_position && !!primary.buttons_left_position) ||
        (!!primary.buttons_top_position_mobile && !!primary.buttons_left_position_mobile);
    const bannerUrl = replacePlaceholderInUrl(asLink(primary.banner_url) || "");
    const router = useRouter();
    const style = {
        "--lg-top": `${primary.buttons_top_position || 0}%`,
        "--lg-left": `${primary.buttons_left_position || 0}%`,
        "--top": `${primary.buttons_top_position_mobile || 0}%`,
        "--left": `${primary.buttons_left_position_mobile || 0}%`,
    };
    return (<div className={classNames(className, bannerUrl ? "cursor-pointer" : "cursor-default")} onClick={() => bannerUrl && router.push(bannerUrl)}>
            <div className="relative h-full bg-contain bg-center bg-no-repeat text-center">
                {primary.image_desktop && (<NextImage className="hidden h-auto w-full max-w-full bg-transparent lg:inline" src={asImageSrc(primary.image_desktop) || ""} width={(_a = primary.image_desktop.dimensions) === null || _a === void 0 ? void 0 : _a.width} height={(_b = primary.image_desktop.dimensions) === null || _b === void 0 ? void 0 : _b.height} sizes="100vw" quality={100} alt={primary.image_desktop.alt || "Single Banner Image Desktop"}/>)}
                {primary.image_mobile && (<NextImage className="inline h-auto w-full max-w-full bg-transparent lg:hidden" src={asImageSrc(primary.image_mobile) || ""} width={(_c = primary.image_mobile.dimensions) === null || _c === void 0 ? void 0 : _c.width} height={(_d = primary.image_mobile.dimensions) === null || _d === void 0 ? void 0 : _d.height} sizes="100vw" quality={100} alt={primary.image_mobile.alt || "Single Banner Image Mobile"}/>)}

                {isFilled.richText(primary.text) && (<>
                        <div className={classNames("absolute left-1/2 flex w-[80%] -translate-x-1/2 -translate-y-1/2 flex-col lg:hidden", {
                "items-center text-center": primary.text_alignment === "center" || !primary.text_alignment,
                "items-start text-left": primary.text_alignment === "left",
                "items-end text-right": primary.text_alignment === "right",
            })} style={{
                color: primary.text_color || undefined,
                top: `${primary.text_top_position_mobile || 50}%`,
            }}>
                            <JCRichText field={primary.text}/>
                        </div>
                        <div className={classNames("absolute left-1/2 hidden w-[80%] -translate-x-1/2 -translate-y-1/2 lg:flex lg:flex-col", {
                "items-center text-center": primary.text_alignment === "center" || !primary.text_alignment,
                "items-start text-left": primary.text_alignment === "left",
                "items-end text-right": primary.text_alignment === "right",
            })} style={{
                color: primary.text_color || undefined,
                top: `${primary.text_top_position || 50}%`,
            }}>
                            <JCRichText field={primary.text}/>
                        </div>
                    </>)}

                {showButtons && (<div style={style} className="absolute top-[var(--top)] left-[var(--left)] -translate-x-1/2 -translate-y-1/2 lg:top-[var(--lg-top)] lg:left-[var(--lg-left)]">
                        <div className="flex gap-x-4.5 gap-y-3 px-0.75 lg:px-0">
                            {items
                .filter((item) => asText(item.button_label))
                .map((item, index) => (<Link to={asLink(item.button_url)} key={index} className={classNames("items-center justify-center", item.is_hide_on_mobile ? "hidden lg:flex" : "flex")} onClick={(e) => e.stopPropagation()}>
                                        <CTA color={primary.button_text_color || ""} bgColor={primary.buttons_bg_color || ""} colorHover={primary.buttons_hover_text_color || ""} bgColorHover={primary.buttons_hover_bg_color || ""} borderWidth={primary.buttons_border_width || 0} borderColor={primary.buttons_border_color || ""} fontSizeMobile={primary.buttons_font_size_mobile || ""}>
                                            {asText(item.button_label)}
                                        </CTA>
                                    </Link>))}
                        </div>
                    </div>)}
            </div>
        </div>);
};
