import { isFilled } from "@prismicio/helpers";
import classNames from "classnames";
import useEmblaCarousel from "embla-carousel-react";
import { useMemo } from "react";
import { ArrowInCarousel } from "../components";
import { usePrevNextButtons } from "../hooks";
const slideSizeMap = {
    2: "lg:basis-1/2",
    3: "lg:basis-1/3",
    4: "lg:basis-1/4",
    5: "lg:basis-1/5",
    6: "lg:basis-1/6",
};
export const alignMap = {
    left: "text-left",
    right: "text-right",
    center: "text-center",
};
export const CarouselBanners = ({ items: _items, displayNumber, CarouselItem, }) => {
    const items = useMemo(() => _items.filter((i) => isFilled.image(i.banner_image)), [_items]);
    const [emblaRef, emblaApi] = useEmblaCarousel({ align: "start" });
    const { prevBtnDisabled, nextBtnDisabled, onPrevButtonClick, onNextButtonClick } = usePrevNextButtons(emblaApi);
    return (<div className="relative">
            <div ref={emblaRef} className="w-full overflow-hidden">
                <div className="-ml-1.5 flex touch-pan-y">
                    {items.map((item, index) => (<CarouselItem key={index} item={item} className={classNames("shrink-0 grow-0 basis-[50%] pl-1.5", slideSizeMap[displayNumber])}/>))}
                </div>
            </div>
            <button className={classNames("absolute top-1/2 left-2 -translate-y-1/2 rotate-180", prevBtnDisabled ? "hidden" : "block")} onClick={onPrevButtonClick}>
                <ArrowInCarousel />
            </button>
            <button className={classNames("absolute top-1/2 right-2 -translate-y-1/2", nextBtnDisabled ? "hidden" : "block")} onClick={onNextButtonClick}>
                <ArrowInCarousel />
            </button>
        </div>);
};
