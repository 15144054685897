import { JCRichText } from "@/components";
import { isFilled } from "@prismicio/helpers";
export const Paragraphs = ({ slice: { items }, className }) => {
    return (<div className={className}>
            {items
            .filter((i) => isFilled.richText(i.paragraph))
            .map((item, index) => (<div key={index} className="mb-5 text-base">
                        <JCRichText field={item.paragraph} paragraphClass="mb-2.5"/>
                    </div>))}
        </div>);
};
