import { asText, isFilled } from "@prismicio/helpers";
import classNames from "classnames";
import { useState } from "react";
import { NextImage } from "../components";
export const BannerSlot = ({ text, textAlignment, textColor, textTopPosition, buttonText, buttonTopPosition, buttonBgColor, buttonTextColor, colorHover, bgColorHover, borderWidth, borderColor, fontSizeMobile, src, quality, sizes, width, height, RichText, CTA, }) => {
    const showButton = !!buttonTopPosition && asText(buttonText);
    const [loaded, setLoaded] = useState(false);
    return (<div className="relative min-h-25 bg-contain text-center">
            <NextImage width={width} height={height} fill={width ? undefined : true} className={classNames("img-fluid w-full", loaded ? "bg-transparent" : "bg-gray-light")} alt={asText(buttonText) || "Small Banner"} sizes={sizes} quality={quality} src={src} onLoad={() => setLoaded(true)}/>
            {text && isFilled.richText(text) && RichText && (<div className={classNames("absolute left-1/2 flex w-[90%] -translate-x-1/2 flex-col", {
                "items-center text-center": textAlignment === "center" || !textAlignment,
                "items-start text-left": textAlignment === "left",
                "items-end text-right": textAlignment === "right",
            })} style={{
                color: textColor || undefined,
                top: `${textTopPosition || 50}%`,
            }}>
                    <RichText field={text}/>
                </div>)}
            {showButton && (<div style={{ top: `${buttonTopPosition}%` }} className="absolute left-1/2 -translate-x-1/2 -translate-y-1/2 whitespace-nowrap">
                    <CTA color={buttonTextColor || ""} bgColor={buttonBgColor || ""} colorHover={colorHover || ""} bgColorHover={bgColorHover || ""} borderWidth={borderWidth || 0} borderColor={borderColor || ""} fontSizeMobile={fontSizeMobile || ""}>
                        {asText(buttonText)}
                    </CTA>
                </div>)}
        </div>);
};
